import { GlobalPairingRoundList } from '../components/Pairing/GlobalRound';

function GlobalPairingRoundListRoute() {
  return <GlobalPairingRoundList />;
}

export const Component = GlobalPairingRoundListRoute;

export function clientLoader() {
  return null;
}
